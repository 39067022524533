export const colors = {
  blue: '#2981fb',
  lightBlue: '#c8e5fe',
  white: '#ffffff',
  red: '#f56c6c',
  green: '#67c23a',
};

export const registerComponents = (G6: any) => {
  const COLLAPSE_ICON = (x: number, y: number, r: number) => [
    ['M', x - r, y - r],
    ['a', r, r, 0, 1, 0, r * 2, 0],
    ['a', r, r, 0, 1, 0, -r * 2, 0],
    ['M', x + 2 - r, y - r],
    ['L', x + r - 2, y - r],
  ];

  const EXPAND_ICON = (x: number, y: number, r: number) => [
    ['M', x - r, y - r],
    ['a', r, r, 0, 1, 0, r * 2, 0],
    ['a', r, r, 0, 1, 0, -r * 2, 0],
    ['M', x + 2 - r, y - r],
    ['L', x + r - 2, y - r],
    ['M', x, y - 2 * r + 2],
    ['L', x, y - 2],
  ];

  G6.registerNode(
    'icon-node',
    {
      options: {
        size: [60, 20],
        stroke: '#91d5ff',
        fill: '#91d5ff',
      },
      draw(cfg: any, group: any) {
        const styles = this.getShapeStyle(cfg);
        const { labelCfg = {} } = cfg;

        const w = styles.width;
        const h = styles.height;

        const keyShape = group.addShape('rect', {
          attrs: {
            ...styles,
            fill: colors.blue,
            stroke: 0,
            x: -w / 2,
            y: -h / 2,
            radius: h / 2,
            padding: 0,
            cursor: 'pointer',
          },
          name: 'element',
        });

        if (cfg.hover) {
          group.addShape('marker', {
            attrs: {
              x: 60 - w / 2,
              y: h / 2 + 5,
              r: 6,
              stroke: colors.green,
              fill: colors.lightBlue,
              cursor: 'pointer',
              symbol: EXPAND_ICON,
            },
            name: 'add-item',
          });

          if (!cfg.children?.length && cfg.depth) {
            group.addShape('marker', {
              attrs: {
                x: 80 - w / 2,
                y: h / 2 + 5,
                r: 6,
                stroke: colors.red,
                fill: colors.lightBlue,
                cursor: 'pointer',
                symbol: COLLAPSE_ICON,
              },
              name: 'remove-item',
            });
          }
        }

        if (cfg.label) {
          group.addShape('text', {
            attrs: {
              ...labelCfg.style,
              y: 4,
              fill: colors.white,
              text: cfg.label,
              textAlign: 'center',
              textBaseline: 'center',
            },
            name: 'element-label',
          });
        }

        return keyShape;
      },
      update: undefined,
    },
    'rect'
  );

  G6.registerEdge('flow-line', {
    draw(cfg: any, group: any) {
      const { startPoint, endPoint } = cfg;
      const shape = group.addShape('path', {
        attrs: {
          stroke: colors.blue,
          path: [
            ['M', startPoint.x, startPoint.y],
            ['L', startPoint.x, (startPoint.y + endPoint.y) / 2],
            ['L', endPoint.x, (startPoint.y + endPoint.y) / 2],
            ['L', endPoint.x, endPoint.y],
          ],
        },
      });
      return shape;
    },
  });
};

export const styles = {
  state: {
    hover: {
      stroke: colors.lightBlue,
      lineWidth: 2,
    },
  },
  node: {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 5,
  },
  edge: {
    stroke: '#91d5ff',
    endArrow: {
      path: 'M 0,0 L 12, 6 L 9,0 L 12, -6 Z',
      fill: '#91d5ff',
      d: -20,
    },
  },
  layout: {
    type: 'compactBox',
    direction: 'TB',
    getId: (d: any) => d.id,
    getHeight: () => 16,
    getWidth: () => 16,
    getVGap: () => 40,
    getHGap: () => 70,
  },
  labelCfg: {
    style: {
      fill: '#000',
      fontSize: 12,
    },
  },
};
