import _ from 'lodash';
import { Node, Edge, NodeWithEdge } from './types';

export const convertToHierarchy = (nodes: Node[], edges: Edge[]) => {
  const nodesWithEdge: NodeWithEdge[] = nodes.map((n: Node) => ({
    ...n,
    parent: edges.find((e: Edge) => e.target === n.id)?.source || null,
    children: [],
  }));

  const tree: NodeWithEdge[] = [];
  const groupedParentNodes: any = _.groupBy(nodesWithEdge, 'parent');

  _.map(groupedParentNodes, (t, key) => {
    if (key === 'null') {
      t.forEach((n: NodeWithEdge) => {
        groupedParentNodes[n.id] = groupedParentNodes[n.id]
          ? {
              ...n,
              children: groupedParentNodes[n.id],
            }
          : n;
        tree.push(groupedParentNodes[n.id]);
      });
      return;
    }
    const parentNode: NodeWithEdge | undefined = nodesWithEdge.find((d) => d.id === key);
    if (parentNode) {
      parentNode.children = t;
      groupedParentNodes[key] = parentNode;
    }
  });

  return tree;
};

export default {};
