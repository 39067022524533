<template>
  <div class="organization" style="width: 100%; height: 100%">
    <div
      id="organization-graph"
      class="organization-graph"
      style="width: 100%; height: 100%; overflow: auto"
    ></div>
  </div>
</template>

// TODO: switch to TS and refactor
<script lang="js">
  import { defineComponent, reactive, onMounted } from 'vue';
  import G6 from '@antv/g6';
  import _ from 'lodash';
  import { getGraph, addGraph } from '@/api/organization';
  import httpRequest from '@/utils/axios/axiosUtils'

  import * as graphConfig from './graphConfig';
  import { convertToHierarchy } from './utils';

  const defaultLabel = '组织名称';

  export default defineComponent({
    setup() {
      const state = reactive({
        nodes: [],
        edges: [],
        currentNode: {},
      });

      const initGraph = (data) => {
        const container = document.getElementById('organization-graph');
        if(container.firstChild) {
          container.removeChild(container.firstChild);
        }
        const width = container?.scrollWidth;
        const height = container?.scrollHeight || 600;

        graphConfig.registerComponents(G6);

        const graph = new G6.TreeGraph({
          container: 'organization-graph',
          width,
          height,
          linkCenter: true,
          modes: {
            default: ['drag-canvas', 'zoom-canvas'],
          },
          defaultNode: {
            type: 'icon-node',
            size: [120, 40],
            style: graphConfig.styles.node,
            labelCfg: graphConfig.styles.labelCfg,
          },
          defaultEdge: {
            type: 'flow-line',
            style: graphConfig.styles.edge,
          },
          nodeStateStyles: graphConfig.styles.state,
          edgeStateStyles: graphConfig.styles.state,
          layout: graphConfig.styles.layout,
        });

        graph.data(data);
        graph.render();
        graph.setMaxZoom(2);
        graph.fitView(0);
        graph.fitCenter();

        graph.on('node:mouseenter', (evt) => {
          evt.currentTarget.updateItem(evt.item, {
            hover: true,
          });
        });

        graph.on('node:mouseleave', (evt) => {
          evt.currentTarget.updateItem(evt.item, {
            hover: false,
          });
        });

        graph.on('node:mouseenter', (evt) => {
          const { item } = evt;
          if (item) {
            graph.setItemState(item, 'hover', true);
          }
        });

        graph.on('node:mouseleave', (evt) => {
          const { item } = evt;
          if (item) {
            graph.setItemState(item, 'hover', false);
          }
        });

        graph.on('node:click', async (evt) => {
          const { item, target } = evt;
          const targetType = target.get('type');
          const name = target.get('name');
          // 增加元素
          if (targetType === 'marker') {
            const model = item.getModel();
            if (name === 'add-item') {
              const res = await addGraph({
                orgName: defaultLabel,
                parent: model.id,
              });
              if (!model.children) {
                model.children = [];
              }
              model.children.push({
                id: res.data,
                label: defaultLabel,
              });
              graph.updateChild(model, model.id);
            } else if (name === 'remove-item') {
              httpRequest.delete(`/org/${model.id}`);
              graph.removeChild(model.id);
            }
          }
        });

        graph.on('node:dblclick', (evt) => {
          const { item, target } = evt;
          const name = target.get('name');

          const { x, y } = item.calculateBBox();

          if (name !== 'element' && name !== 'element-label') {
            return;
          }
          const model = item.getModel();
          const currentGraph = evt.currentTarget;
          const realPosition = evt.currentTarget.getClientByPoint(x, y);
          const el = document.createElement('div');
          el.style.fontSize = `${model?.labelCfg?.style?.fontSize}px`;
          el.style.position = 'fixed';
          el.style.top = `${realPosition.y}px`;
          el.style.left = `${realPosition.x}px`;
          el.style.height = `${model.label?.model?.size[0]}px`;
          el.style.transformOrigin = 'top left';
          el.style.transform = `scale(${evt.currentTarget.getZoom()})`;
          const input = document.createElement('input');
          input.style.border = 'none';
          input.value = model.label;
          input.style.width = `${model?.size[0]}px`;
          input.style.height = `${model?.size[1]}px`;
          input.style.background = graphConfig.colors.lightBlue;
          input.style.padding = '0 12px';
          input.style.borderRadius = `${model?.size[1] / 2}px`;
          input.className = 'dice-input';
          el.className = 'dice-input';
          el.appendChild(input);
          document.body.appendChild(el);
          input.focus();
          const destroyEl = () => {
            document.body.removeChild(el);
          };
          const clickEvt = (event) => {
            if (!(event.target && event.target.className && event.target.className.includes('dice-input'))) {
              window.removeEventListener('mousedown', clickEvt);
              window.removeEventListener('scroll', clickEvt);
              const value = _.trim(input.value);
              if (value) {
                httpRequest.put(`/org/${model.id}`, { name: value });
                currentGraph.updateItem(item, {
                  label: value,
                });
              }
              currentGraph.layout(false);
              currentGraph.off('wheelZoom', clickEvt);
              destroyEl();
            }
          };
          currentGraph.on('wheelZoom', clickEvt);
          window.addEventListener('mousedown', clickEvt);
          window.addEventListener('scroll', clickEvt);
          input.addEventListener('keyup', (event) => {
            if (event.key === 'Enter') {
              clickEvt({
                target: {},
              });
            }
          });
        })

        if (typeof window !== 'undefined')
          window.onresize = () => {
            if (!graph || graph.get('destroyed')) return;
            if (!container || !container.scrollWidth || !container.scrollHeight) return;
            graph.changeSize(container.scrollWidth, container.scrollHeight);
          };
      };

      onMounted(async () => {
        const res = await getGraph();
        let hierarchies = convertToHierarchy(res.data.nodes, res.data.edges);
        if (!res?.data?.nodes?.length) {
          const rootNode = await addGraph({
            orgName: defaultLabel,
          });
          hierarchies = convertToHierarchy([{
            id: rootNode.data,
            label: defaultLabel,
          }], []);
        }
        initGraph(hierarchies[0]);
      });

      return {
        state,
        onMounted,
      };
    },
  });
</script>
